<template>
  <div>
    <!-- Multi Step Page -->
    <div class="create-page" v-if="ready">
      <div class="create-page__header">
        <CRow class="mr-0 ml-0">
          <CCol col="12" sm="6" class="d-flex align-items-center pl-0">
            <div class="zq-page-title-wrapper d-flex">
              <h3 class="zq-page-title">{{ texts.createPage.title }}</h3>
              <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
            </div>
          </CCol>
          <CCol col="12" sm="6">
            <ActionCreateBtns
              :currentStep="currentStep"
              :totalStep="totalStep"
              :finishAction="createEntity"
              @updateCurrentStep="updateCurrentStep"
              @next-step="nextStep"
            />
          </CCol>
        </CRow>
        <WizardHeader
          :currentStep="currentStep"
          :steps="steps"
          @updateCurrentStep="updateCurrentStep"
        />
      </div>
      <div class="content">
        <FormBuilder
          v-if="currentStep === 0"
          :list="formList"
          @updated="updateSettingsData"
          :page="{ title: texts.createPage.title, info: descriptions }"
          :isCreateHeader="false"
          :isSettingsStep="true"
        />
        <CreateRules
          v-if="steps.find(step => step.key === 'rules') && currentStep === getStepNumber('rules')"
          context="contest"
          @updateRulesData="updateRulesData"
          @updateDataFromTemplate="updateDataFromTemplate"
          :rulesQueryData="rulesQueryData"
          :stepNumber="getStepNumber('rules') + 1"
          :key="createRulesKey"
          :model="model"
        />
        <CreateDependantOn
          v-if="steps.find(step => step.key === 'dependantOn') && currentStep === getStepNumber('dependantOn')"
          @updateDependantOnData="updateDependantOnData"
          @resetDependantValidation="resetDependantValidation"
          @updateCurrentTypes="updateDependantOnCurrentTypes"
          :isValid="dependantOnDataValidate"
          :dependantOnData="dependantOnData"
          :stepNumber="getStepNumber('dependantOn') + 1"
        />
        <CreateCompetitionScheduling
          ref="createCompetitionScheduling"
          v-if="steps.find(step => step.key === 'strategies') && currentStep === getStepNumber('strategies')"
          @updateSchedulingData="updateSchedulingData"
          @updateConstraints="getConstraints"
          @resetSchedulingValidate="resetSchedulingValidate"
          @updateSchedulingSwitch="updateSchedulingSwitch"
          :constraintsData="constraints"
          :schedulingData="schedulingData"
          :isStrategy="schedulingDataValid_scoringStrategy"
          creationType="contest"
          :descriptions="schedulingDescriptions"
          :isStartDate="schedulingDataValid_isStartDate"
          :isEndDate="schedulingDataValid_isEndDate"
          :competitionEndDate="competitionEndDate"
          :competitionStartDate="competitionStartDate"
        />
        <CreateTranslations
          v-if="steps.find(step => step.key === 'translations') && currentStep === getStepNumber('translations')"
          :entityData="settingsData"
          :translatableFields="translatableFields"
          :translationsData="translationsData"
          @updated="updateTranslationsData"
          :stepNumber="getStepNumber('translations') + 1"
        />
        <CreateRewards
          v-if="steps.find(step => step.key === 'rewards') && currentStep === getStepNumber('rewards')"
          @updateRewardData="updateRewardData"
          :rewardsData="rewardsData"
          :entityType="'Contest'"
          :model="model"
        />
        <ContestSummaryStep
          v-if="currentStep === getStepNumber('summary')"
          :settingsData="settingsData"
          :rulesData="rulesData"
          :dependantOnData="dependantOnData"
          :schedulingData="schedulingData"
          :rewardsData="rewardsData"
          :translationsData="translationsData"
          :translatableFields="translatableFields"
          :stepNumber="getStepNumber('summary') + 1"
          :model="model"
        />
      </div>
    </div>
    <PreviewSpiner
      v-else
      :texts="['Validating...', 'Preparing...', 'Redirecting...']"
    />
    <!-- /Multi Step Page -->
    <Modal
      :modalShow="cloneModal"
      :messageGeneral="getCloneMessage()"
      :title="getCloneTitle()"
      :cancelBtnLabel="'Skip'"
      @doFunction="cloneContests"
      v-on:toggle-modal="skipClone"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ActionCreateBtns from '@/shared/components/steps/ActionCreateBtns';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import WizardHeader from '@/shared/components/steps/Header';
import CreateTranslations from '@/shared/components/supportModels/translations/CreateTranslations';
import CreateRules from '@/shared/components/supportModels/rules/CreateRules';
import CreateDependantOn from '@/shared/components/supportModels/dependantOn/CreateDependantOn';
import CreateCompetitionScheduling from '@/shared/components/supportModels/scheduling/CreateCompetitionScheduling';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards';
import ContestSummaryStep from '@/shared/components/steps/ContestSummaryStep';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { translationsTransform } from '@/utils/translationsUtils';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { contests } from '@/config/descriptions/contests.json';
import { contestsTexts } from '@/config/pageTexts/contests.json';
import contestFields from '@/generated/ziqni/store/modules/contests/fields';
import { cloneDeep } from 'lodash';
import { competitions } from "@/config/descriptions/competitions.json";
import { contestQuery } from "@/helpers/rules/constestQuery";
import PreviewSpiner from "@/shared/UI/Spiner.vue";
import Modal from '@/shared/UI/Modal';

export default {
  name: 'CreateContest',
  components: {
    Modal,
    PreviewSpiner,
    ActionCreateBtns,
    IconWithTooltip,
    WizardHeader,
    CreateTranslations,
    CreateRules,
    CreateDependantOn,
    CreateCompetitionScheduling,
    CreateRewards,
    ContestSummaryStep,
  },
  props: {
    isCreateCompetition: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      ready: true,
      model: 'contests',
      cloneModal: false,
      cloneMessage: '',
      currentStep: 0,
      totalStep: 0,
      createRulesKey: 0,
      descriptions: {
        ...contests.create,
      },
      texts: {
        ...contestsTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      dependantOnData: {
        currentTypes: {},
        formData: {
          shouldMatchAtLeast: null,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        },
        selectedData: []
      },
      dependantOnDataValidate: null,
      schedulingData: {
        defaultRanking: true,
        scoreDesc: false,
        scoreFirst: false,
        ignoreTime: false,
        ignoreScore: false,
        timeDesc: false,
        excludeIfGoalNotReached: true,
        round: 1,
        entrantsFromContest: [],
        scheduledStartDate: null,
        scheduledEndDate: null,
        strategies: {
          strategyType: "",
          rankingStrategy: {
            constraints: []
          },
          scoringStrategy: {
            limitUpdatesTo: 0,
            sumBestXOf: 0,
            lastUpdateTimeStamp: 0,
            recordTimeWhenSumReaches: 0
          }
        },
      },
      schedulingDataValid_startDate: null,
      schedulingDataValid_every: null,
      schedulingDataValid_scheduleOccurrencesLimit: null,
      schedulingDataValid_scheduleType: null,
      schedulingDataValid_scoringStrategy: null,
      schedulingDataValid_isStartDate: null,
      schedulingDataValid_isEndDate: null,
      rewardsData: [],
      transformedRewards: [],
      rulesData: contestQuery,
      constraints: [],
      stepsOrder: {
        strategies: 1,
        rewards: 2,
        rules: 3,
        translations: 4,
      },
      rulesQueryData: {},
      competitionEndDate: null,
      competitionStartDate: null,
      cloneIds: []
    };
  },
  computed: {
    ...mapGetters('contests', ['message', 'loading']),
    ...mapGetters('competitions', ['competition']),
    ...mapGetters('languages', {storeLanguages: 'languages'}),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    schedulingDescriptions() {
      return {
        ...competitions.create.multi.scheduling,
      }
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('contests', ['handleCreateContests', 'handleGetContestsToClone']),
    ...mapActions('languages', ['handleGetLanguages']),
    ...mapActions('rewardTypes', ['handleGetRewardTypes']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute, {
        competitionId: this.$route.params.id,
        competitionName: this.competition ? this.competition.name : '',
      });

      if (this.$route.query.cloneIds) {
        this.cloneIds = JSON.parse(decodeURIComponent(this.$route.query.cloneIds));
      }

      this.competitionStartDate = this.competition.scheduledStartDate;
      this.competitionEndDate = this.competition.scheduledEndDate;

      if (this.$route.params.cloneId) {
        const cloneId = this.$route.params.cloneId
        this.handleGetContestsToClone({id: cloneId}).then(res => {
          this.setCloneData(res[0])
        })
      }

      this.formList = fieldHelpers.prepareCreateFormList(
        contestFields,
        contestsTexts.createPage,
        contests.create
      );

      let formSteps = [];

      if (this.model === 'contests') {
        this.formList = this.formList.filter(formItem => {
          return formItem.key !== 'scheduledStartDate' && formItem.key !== 'scheduledEndDate';
        });
      }

      this.formList = this.formList.filter(formItem => {
        return formItem.key !== 'round' && formItem.key !== 'row' && formItem.key !== 'entrantsFromContest'
      })

      this.formList.forEach(field => {
        if (field.key === 'minNumberOfEntrants' || field.key === 'maxNumberOfEntrants') {
          field.placeholder = 'Unlimited'
        }
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.required && field.key !== 'minNumberOfEntrants') {
          this.requiredFields.push(field.key)
        }
      })

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
          if (step.key === 'strategies') {
            this.requiredFields.strategies = [];
            this.requiredFields.strategies.push('scheduledStartDate');
            this.requiredFields.strategies.push('scheduledEndDate');
            this.requiredFields.strategies.push('strategyType');
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: this.stepsOrder[step.key],
          });
          this.stepKeys.push(step.key);
        })

        this.steps.sort((a, b) => a.step > b.step ? 1 : -1);

        this.translatableFields = contestFields.baseFields.translatableFields.filter(item => item !== 'groupStageLabel');

        this.lastStep.step = this.steps.length;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    async setCloneData(contestData) {
      // Settings
      const contestFormList = fieldHelpers.prepareEditFormList(
          contestFields,
          this.texts.editPage,
          this.descriptions,
          contestData
      );
      const availableFormFields = this.formList.map(item => item.key);
      this.formList = contestFormList.filter(item => availableFormFields.includes(item.key));

      // Strategies
      if (contestData.strategies) {
        const { round, scheduledStartDate, scheduledEndDate } = contestData;

        const strategies = cloneDeep(contestData.strategies);

        this.updateSchedulingData({
          round: round,
          strategies: strategies,
          scheduledStartDate: this.competitionStartDate,
          scheduledEndDate: this.competitionEndDate,
        });
      }

      // Rules
      this.rulesQueryData = this.getCloneRulesData(contestData.rules);
      this.updateRulesData(this.rulesQueryData);

      // Rewards
      if (contestData.rewards.length) {
        const cloneRewards = cloneDeep(contestData.rewards);
        const rewardTypeIds = cloneRewards.map(reward => reward.rewardTypeId);
        const rewardTypes = await this.handleGetRewardTypes({idArray: rewardTypeIds});

        cloneRewards.forEach(reward => {
          reward.rewardType = rewardTypes.find(rewardType => rewardType.id === reward.rewardTypeId);
        })

        this.updateRewardData(cloneRewards);
      }

      // Translations
      if (contestData.translations) {
        const languages = await this.handleGetLanguages({idArray: []});
        const map = this.getMapLanguages(languages)

        const contestTranslations = contestData.translations.map(item => {
          const translations = item.translations.reduce((acc, translation) => {
            return {...acc, [translation.fieldName]: translation.text}
          }, {})
          return {languageKey: item.languageKey, translations: translations}
        }).reduce((obj, item) => {
          obj[item['languageKey']] = item.translations
          return obj
        }, {});

        const val = languages.reduce((acc, lang) => {
          const language = contestTranslations[lang.key]
              ? {[lang.name]: contestTranslations[lang.key]}
              : {[lang.name]: {}}
          return {...acc, ...language}
        }, {})

        this.updateTranslationsData({map, val})
      }
      this.cloneModal = true;
    },
    getMapLanguages(languages) {
      return languages.reduce((acc, language) => {
        const lang = {[language.name]: language.key}
        return {...acc,  ...lang};
      }, {})
    },
    getCloneRulesData(rules) {
      let query = {};
      if (rules.length) {
        rules.forEach(action => {
          for (let key in action.rules) {
            if (action.rules[key] === null) {
              if (key === 'rules') {
                action.rules[key] = [];
              } else {
                action.rules[key] ='';
              }
            }
          }
          query[action.action] = action.rules;
        });
      }
      return query;
    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);
      if (!invalidFields.length) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
    },
    getInvalidFields() {
      let result = [];
      //TODO: remove after implementing constraint
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }
      if (this.getStepNumber('scheduling') === this.currentStep) {
        for (let key in this.schedulingData) {
          if (this.requiredFields.scheduling.includes(key) && (this.schedulingData[key] === null || this.schedulingData[key] === '')) {
            result.push(key);
          }
        }
      }
      if (this.getStepNumber('strategies') === this.currentStep) {
        for (let key in this.schedulingData) {
          const endDate = new Date(this.schedulingData.scheduledEndDate);
          const currentDate = new Date();

          if (this.schedulingData.scheduledEndDate && endDate <= currentDate && this.$route.query.cloneIds) {
            result.push('scheduledEndDate');
            this.$refs.createCompetitionScheduling.setInvalidEndDate()
          }
          if (this.requiredFields.strategies.includes(key) && (this.schedulingData[key] === null || this.schedulingData[key] === '')) {
            result.push(key);
          }
        }
        if (!this.schedulingData.strategies.strategyType) {
          result.push('strategyType');
        }
      }

      return result;
    },
    getStepNumber(key) {
      let entityObject = this.steps.find(step => step.key === key);
      if (entityObject !== undefined && entityObject.hasOwnProperty('step')) {
        return entityObject.step;
      } else {
        return -1;
      }
    },
    getConstraints(val) {
      this.constraints = val;
    },
    updateSchedulingSwitch(field, val) {
      this.schedulingData[field] = val;
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    updateDependantOnData(val) {
      this.dependantOnData = val;
    },
    resetDependantValidation() {
      this.dependantOnDataValidate = null;
    },
    updateDependantOnCurrentTypes(val) {
      this.dependantOnData.currentTypes = val;
    },
    updateSchedulingData(val) {
      this.schedulingData = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_startDate = null;
      this.schedulingDataValid_every = null;
      this.schedulingDataValid_scheduleOccurrencesLimit = null;
      this.schedulingDataValid_scheduleType = null;
    },
    updateTranslationsData(obj) {
      this.translationsMap = obj.map;
      this.translationsData = obj.val;
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType ? reward.rewardType.id : reward.rewardTypeId;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;

          if (!this.$route.params.cloneId) {
            delete reward.rewardType;
          }

          transformedRewards.push(reward);
        })
      }
      this.transformedRewards = transformedRewards;
    },
    updateDataFromTemplate(val) {
      this.rulesQueryData = cloneDeep(val);
      for (let key in this.rulesData) {
        if (val[key]) {
          if (val[key].id) {
            delete val[key].id;
          }
          this.rulesData[key] = val[key];
        } else {
          this.rulesData[key] = contestQuery[key];
        }
      }
      this.createRulesKey++;
    },
    updateRulesData(val) {
      this.rulesQueryData = cloneDeep(val);
      for (let key in val) {
        if (val[key]) {
          this.rulesData[key] = val[key];
        }
      }
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (
          !invalidFieldElement.classList.contains('zq--form-row')
          && !invalidFieldElement.classList.contains('zq--data-picker')
        ) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    getRules() {
      let rules = [];
      const rulesData = cloneDeep(this.rulesData)
      for (const action in rulesData) {
        if (rulesData[action].then && rulesData[action].then.length) {
          rulesData[action].then = rulesData[action].then.map(t => {
            if (!t.arguments) {t.arguments = []}
            return t;
          })
        }

        let ruleObject = {};

        ruleObject.entityId = '';
        ruleObject.action = action;
        ruleObject.context = 'contest';
        ruleObject.rules = rulesData[action];
        ruleObject.rules.type = 'condition';
        ruleObject.rules.lineNumber = 1;

        rules.push(ruleObject);
      }

      return rules
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    getCloneTitle () {
      let title = 'Clone contest';

      if (this.$route.query.prev && this.$route.query.prev.length) {
        title = '"' + this.$route.query.prev + '" has been cloned';
      }
      return title;
    },
    getCloneMessage() {
      let title = '';

      if (this.formList && this.formList.length) {
        const idx = this.formList.findIndex(i => i.key === 'name');
        if (idx !== -1) {
          title = 'Would you want to clone "' + this.formList[idx].value +'"?'
        }
      }

      return title;
    },
    cloneContests () {
      this.cloneModal = false;
    },
    skipClone () {
      this.cloneModal = false;

      if (Array.isArray(this.cloneIds) && this.cloneIds.length) {
        const id = this.cloneIds.shift();
        this.$router.push({
          name: 'CloneContest',
          params: { cloneId: id },
          query: {
            cloneIds: encodeURIComponent(JSON.stringify(this.cloneIds))
          }
        });
      } else {
        this.$router.push({
          name: 'PreviewCompetition',
          params: {
            id: this.$route.params.id
          }
        })
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }
      if (Object.keys(this.translationsData).length) {
        formData.translations = translationsTransform(this.translationsData, this.translationsMap);
      }
      if (this.transformedRewards.length) {
        formData.rewards = this.transformedRewards;
      }
      if (Object.keys(this.schedulingData).length && this.schedulingData.scheduleType) {
        formData.scheduling = this.schedulingData;
      }
      if (Object.keys(this.rulesData).length) {
        formData.rules = this.getRules();
      }
      if (Object.keys(this.dependantOnData).length && this.dependantOnData.formData) {
        let isDependantOn = false;
        for (let key in this.dependantOnData.formData.dependantOn) {
          if (this.dependantOnData.formData.dependantOn[key].length) {
            isDependantOn = true;
          }
        }
        if (isDependantOn) {
          formData.dependantOn = {};
          formData.dependantOn.dependantOn = this.dependantOnData.formData.dependantOn;
          formData.dependantOn.entityType = 'member';
        }
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }
      formData.competitionId = this.$route.params.id;
      formData.round = this.schedulingData.round;
      formData.scheduledStartDate = this.schedulingData.scheduledStartDate;
      formData.scheduledEndDate = this.schedulingData.scheduledEndDate;
      formData.strategies = this.schedulingData.strategies;
      formData.row = null;
      formData.minNumberOfEntrants = formData.minNumberOfEntrants && formData.minNumberOfEntrants !== 'Unlimited'
          ? formData.minNumberOfEntrants
          : 0;
      formData.maxNumberOfEntrants = formData.maxNumberOfEntrants && formData.maxNumberOfEntrants !== 'Unlimited'
          ? formData.maxNumberOfEntrants
          : null;

      if (this.schedulingData.entrantsFromContest && this.schedulingData.entrantsFromContest.length) {
        formData.entrantsFromContest = [];
        this.schedulingData.entrantsFromContest.forEach(e => {
          formData.entrantsFromContest.push(e.id)
        });
      }

      delete formData.addConstraints

      if (!this.isCreateCompetition) {
        this.ready = false;
        this.handleCreateContests({createContestForCompetitionRequest: JSON.parse(JSON.stringify(formData))})
          .then(data => {
            if (data.length) {
              if (Array.isArray(this.cloneIds) && this.cloneIds.length) {
                const id = this.cloneIds.shift();
                this.$router.push({
                  name: 'CloneContest',
                  params: { cloneId: id },
                  query: {
                    prev: formData.name ?? '',
                    cloneIds: encodeURIComponent(JSON.stringify(this.cloneIds))
                  }
                });
              } else {
                this.$router.push({
                  name: 'PreviewContest',
                  params: {
                    id: this.$route.params.id,
                    contestId: data[0].id,
                  }
                })
              }
            } else {
              this.ready = true;
              console.log('Something went wrong');
            }
          });
      } else {
        this.$emit('updateContestData', JSON.parse(JSON.stringify(formData)))
      }
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
